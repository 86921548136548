import React from "react";

import "./TermsAndConditions.css";

export const TermsAndConditions = () => {
  return (
    <div>
      <p className="c1">
        <span className="c15">TERMS AND CONDITIONS</span>
      </p>
      <p className="c1 c6">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c14 c16">Last Updated 2020-04-08</span>
      </p>
      <p className="c1 c6">
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c17">1.Agreement to Terms</span>
      </p>
      <p className="c1 c6">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c4">
          1.1 These Terms and Conditions constitute a legally binding agreement
          made between{" "}
        </span>
        <span className="c5">
          HealthConnect Online and MERWELL MÜHENDİSLİK LİMİTED ŞİRKETİ, located
          at ESENTEPE MAH. BÜYÜKDERE CAD. OYAL IŞMR.AP.N.108/1/912,Istanbul,
          Turkey, Istanbul, Turkey, ESENTEPE MAH __________ Turkey (we, us)
        </span>
        <span className="c4">), concerning your access to and use of the </span>
        <span className="c5">
          healthconnect online(healthconnect.online and healthconnect.org.au)
        </span>
        <span className="c4">
          website as well as any related applications (the{" "}
        </span>
        <span className="c5">Site</span>
        <span className="c4">).</span>
        <span className="c4"></span>
        <span className="c8 c14"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span className="c4">The Site provides the following services: </span>
        <span className="c5">
          we set our audience to everyone that prefer to buy and sell with
          reasonable prices and also attractive incentives like the ability of
          trading in almost any crypto Currency
        </span>
        <span className="c4">(</span>
        <span className="c5">Services</span>
        <span className="c4">
          ). You agree that by accessing the Site and/or Services, you have
          read, understood, and agree to be bound by all of these Terms and
          Conditions.
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span className="c5">
          If you do not agree with all of these Terms and Conditions, then you
          are prohibited from using the Site and Services and you must
          discontinue use immediately
        </span>
        <span className="c4">
          . We recommend that you print a copy of these Terms and Conditions for
          future reference.
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span>1.2 </span>
        <span className="c4">
          The supplemental policies set out in Section 1.7 below, as well as any
          supplemental terms and condition or documents that may be posted on
          the Site from time to time, are expressly incorporated by reference.
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span>1.3 </span>
        <span className="c4">
          We may make changes to these Terms and Conditions at any time. The
          updated version of these Terms and Conditions will be indicated by an
          updated &ldquo;Revised&rdquo; date and the updated version will be
          effective as soon as it is accessible. You are responsible for
          reviewing these Terms and Conditions to stay informed of updates. Your
          continued use of the Site represents that you have accepted such
          changes.
        </span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span>1.4 </span>
        <span className="c4">
          We may update or change the Site from time to time to reflect changes
          to our products, our users&#39; needs and/or our business priorities.
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span>1.5 </span>
        <span className="c4">
          Our site is directed to people residing in All over the world. The
          information provided on the Site is not intended for distribution to
          or use by any person or entity in any jurisdiction or country where
          such distribution or use would be contrary to law or regulation or
          which would subject us to any registration requirement within such
          jurisdiction or country.
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span>1.6 </span>
        <span className="c4">
          The Site is intended for users who are at least 18 years old. If you
          are under the age of 18, you are not permitted to register for the
          Site or use the Services without parental permission.
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span>1.7 </span>
        <span className="c4">
          Additional policies which also apply to your use of the Site include:
        </span>
        <span className="c8 c14"></span>
      </p>
      <p className="c1 c6">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c11">&#9679;</span>
        <span className="c21"></span>
        <span className="c4">
          Our Privacy Notice http://healthconnect.online/privacy-policy, which
          sets out the terms on which we process any personal data we collect
          from you, or that you provide to us. By using the Site, you consent to
          such processing and you warrant that all data provided by you is
          accurate.
        </span>
      </p>
      <p className="c1">
        <span className="c11">&#9679;</span>
        <span>
          Certain parts of this Site can be used only on payment of a fee.
        </span>
      </p>
      <p className="c1 c6">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span>2. </span>
        <span className="c12 c9"></span>
        <span className="c9 c17">Acceptable Use</span>
        <span className="c8 c9"></span>
        <span className="c8 c14"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span>2.1 </span>
        <span className="c4">
          You may not access or use the Site for any purpose other than that for
          which we make the site and our services available. The Site may not be
          used in connection with any commercial endeavors except those that are
          specifically endorsed or approved by us.
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span>2.2 </span>
        <span className="c4">As a user of this Site, you agree not to: </span>
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c4"></span>
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c11">&#9679;</span>
        <span className="c4">
          Systematically retrieve data or other content from the Site to a
          compile database or directory without written permission from us
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c11">&#9679;</span>
        <span className="c4">
          Make any unauthorized use of the Site, including collecting usernames
          and/or email addresses of users to send unsolicited email or creating
          user accounts under false pretenses
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c11">&#9679;</span>
        <span className="c4">
          Use the Site to advertise or sell goods and services
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c11">&#9679;</span>
        <span className="c4">
          Circumvent, disable, or otherwise interfere with security-related
          features of the Site, including features that prevent or restrict the
          use or copying of any content or enforce limitations on the use
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c11">&#9679;</span>
        <span className="c4">
          Engage in unauthorized framing of or linking to the Site
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c11">&#9679;</span>
        <span className="c4">
          Trick, defraud, or mislead us and other users, especially in any
          attempt to learn sensitive account information such as user passwords
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c11">&#9679;</span>
        <span className="c4">
          Make improper use of our support services, or submit false reports of
          abuse or misconduct
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c11">&#9679;</span>
        <span className="c4">
          Engage in any automated use of the system, such as using scripts to
          send comments or messages, or using any data mining, robots, or
          similar data gathering and extraction tools
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c11">&#9679;</span>
        <span className="c4">
          Interfere with, disrupt, or create an undue burden on the Site or the
          networks and services connected to the Site
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c11">&#9679;</span>
        <span className="c4">
          Attempt to impersonate another user or person, or use the username of
          another user
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c11">&#9679;</span>
        <span className="c4">Sell or otherwise transfer your profile</span>
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c11">&#9679;</span>
        <span className="c4">
          Use any information obtained from the Site in order to harass, abuse,
          or harm another person
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c11">&#9679;</span>
        <span className="c4">
          Use the Site or our content as part of any effort to compete with us
          or to create a revenue-generating endeavor or commercial enterprise
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c11">&#9679;</span>
        <span className="c4">
          Decipher, decompile, disassemble, or reverse engineer any of the
          software comprising or in any way making up a part of the Site
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c11">&#9679;</span>
        <span className="c4">
          Attempt to access any portions of the Site that you are restricted
          from accessing
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c11">&#9679;</span>
        <span className="c4">
          Harass, annoy, intimidate, or threaten any of our employees, agents,
          or other users
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c11">&#9679;</span>
        <span className="c4">
          Upload or transmit (or attempt to upload or to transmit) viruses,
          Trojan horses, or other material that interferes with any
          party&rsquo;s uninterrupted use and enjoyment of the Site, or any
          material that acts as a passive or active information collection or
          transmission mechanism
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c11">&#9679;</span>
        <span className="c4">
          Use, launch, or engage in any automated use of the system, such as
          using scripts to send comments or messages, robots, scrapers, offline
          readers, or similar data gathering and extraction tools
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c11">&#9679;</span>
        <span className="c4">
          Disparage, tarnish, or otherwise harm, in our opinion, us and/or the
          Site
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c11">&#9679;</span>
        <span className="c4">
          Use the Site in a manner inconsistent with any applicable laws or
          regulations
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c11">&#9679;</span>
        <span className="c4">
          Threaten users with negative feedback or offering services solely to
          give positive feedback to users
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c11">&#9679;</span>
        <span className="c4">
          Misrepresent experience, skills, or information about a User
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c11">&#9679;</span>
        <span className="c4">
          Advertise products or services not intended by us
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c11">&#9679;</span>
        <span className="c4">
          Falsely imply a relationship with us or another company with whom you
          do not have a relationship
        </span>
        <span className="c8 c9"></span>
        <span className="c8 c14"></span>
      </p>
      <p className="c1 c6">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c17 c14">3. Information you provide to us</span>
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span>3.1 </span>
        <span className="c4">
          You represent and warrant that: (a) all registration information you
          submit will be true, accurate, current, and complete and relate to you
          and not a third party; (b) you will maintain the accuracy of such
          information and promptly update such information as necessary; (c) you
          will keep your password confidential and will be responsible for all
          use of your password and account; (d) you have the legal capacity and
          you agree to comply with these Terms and Conditions; and (e) you are
          not a minor in the jurisdiction in which you reside, or if a minor,
          you have received parental permission to use the Site.
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span className="c4">
          If you know or suspect that anyone other than you knows your user
          information (such as an identification code or user name) and/or
          password you must promptly notify us at info@healthconnect.online.
        </span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span>3.2 </span>
        <span className="c4">
          If you provide any information that is untrue, inaccurate, not current
          or incomplete, we may suspend or terminate your account. We may remove
          or change a user name you select if we determine that such user name
          is inappropriate.
        </span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span className="c4">
          3.3 As part of the functionality of the Site, you may link your
          account with online accounts you may have with third party service
          providers (each such account, a{" "}
        </span>
        <span className="c5">Third Party Account</span>
        <span className="c4">
          ) by either: (a) providing your Third Party Account login information
          through the Site; or (b) allowing us to access your Third Party
          Account, as is permitted under the applicable terms and conditions
          that govern your use of each Third Party Account.
        </span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span className="c4">
          You represent that you are entitled to disclose your Third Party
          Account login information to us and/or grant us access to your Third
          Party Account without breach by you of any of the terms and conditions
          that govern your use of the applicable Third Party Account and without
          obligating us to pay any fees or making us subject to any usage
          limitations imposed by such third party service providers.
        </span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span className="c4">
          3.4 By granting us access to any Third Party Accounts, you understand
          that (a) we may access, make available and store (if applicable) any
          content that you have provided to and stored in your Third Party
          Account (the &ldquo;
        </span>
        <span className="c5">Social Network Content</span>
        <span className="c4">
          &rdquo;) so that it is available on and through the Site via your
          account, including without limitation any friend lists; and (b) we may
          submit and receive additional information to your Third Party Account
          to the extent you are notified when you link your account with the
          Third Party Account.
        </span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span className="c4">
          Depending on the Third Party Accounts you choose and subject to the
          privacy settings that you have set in such Third Party Accounts,
          personally identifiable information that you post to your Third Party
          Accounts may be available on and through your account on the Site.
          Please note that if a Third Party Account or associated service
          becomes unavailable or our access to such Third Party Account is
          terminated by the third party service provider, then Social Network
          Content may no longer be available on and through the Site.
        </span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span className="c4">
          You will have the ability to disable the connection between your
          account on the Site and your Third Party Accounts at any time.{" "}
        </span>
        <span className="c5">
          Please note that your relationship with the third party service
          providers associated with your third party accounts is governed solely
          by your agreement(s) with such third party service providers.
        </span>
        <span className="c4">
          We make no effort to review any Social Network Content for any
          purpose, including but not limited to, for accuracy, legality or
          non-infringement, and we are not responsible for any Social Network
          Content.
        </span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span className="c4">
          You acknowledge and agree that we may access your email address book
          associated with a Third Party Account and your contacts list stored on
          your mobile device or tablet computer solely for purposes of
          identifying and informing you of those contacts who have also
          registered to use the Site. At your email request to
          info@healthconnect.online or through your account settings (if
          applicable), we will deactivate the connection between the Site and
          your Third Party Account and attempt to delete any information stored
          on our servers that was obtained through such Third Party Account,
          except the username and profile picture that became associated with
          your account.{" "}
        </span>
        <span className="c8 c9"></span>
        <span className="c8"></span>
        <span className="c4"></span>
      </p>
      <p className="c1 c6">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span>4.</span>
        <span className="c12 c9"></span>
        <span></span>
        <span className="c17 c14">Content you provide to us </span>
      </p>
      <p className="c1 c6">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span>4.1 </span>
        <span className="c4">
          There may be opportunities for you to post content to the Site or send
          feedback to us (
        </span>
        <span className="c5">User Content</span>
        <span className="c4">
          ). You understand and agree that your User Content may be viewed by
          other users on the Site, and that they may be able to see who has
          posted that User Content.
        </span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span className="c4">4.2 </span>
        <span className="c7 c13">
          You further agree that we can use your User Content for any other
          purposes whatsoever in perpetuity without payment to you, and combine
          your User Content with other content for use within the Site and
          otherwise. We do not have to attribute your User Content to you
        </span>
        <span>.</span>
        <span className="c4"></span>
        <span className="c7 c13">
          When you upload or post content to our site, you grant us the
          following rights to use that content:
        </span>
        <span className="c4"> </span>
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span>4.3</span>
        <span className="c4">
          You warrant that any User Content does comply with our Acceptable Use
          Policy, and you will be liable to us and indemnify us for any breach
          of that warranty. This means you will be responsible for any loss or
          damage we suffer as a result of your breach of this warranty.
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span>4.4 </span>
        <span className="c4">
          We have the right to remove any User Content you put on the Site if,
          in our opinion, such User Content does not comply with the Acceptable
          Use Policy.
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span>4.5 </span>
        <span className="c4">
          We are not responsible and accept no liability for any User Content
          including any such content that contains incorrect information or is
          defamatory or loss of User Content. We accept no obligation to screen,
          edit or monitor any User Content but we reserve the right to remove,
          screen and/or edit any User Content without notice and at any time.
          User Content has not been verified or approved by us and the views
          expressed by other users on the Site do not represent our views or
          values
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span>4.6 </span>
        <span className="c4">
          If you wish to complain about User Content uploaded by other users
          please contact us at info@healthconnect.online.
        </span>
        <span className="c8"></span>
        <span className="c4"></span>
        <span className="c8 c14"></span>
      </p>
      <p className="c1 c6">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c17">5. </span>
        <span className="c17 c14">Our content</span>
        <span className="c8 c14"></span>
      </p>
      <p className="c1 c6">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span>5.1 </span>
        <span className="c4">
          Unless otherwise indicated, the Site and Services including source
          code, databases, functionality, software, website designs, audio,
          video, text, photographs, and graphics on the Site (
        </span>
        <span className="c5">Our Content</span>
        <span className="c4">
          ) are owned or licensed to us, and are protected by copyright and
          trade mark laws.
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span>5.2 </span>
        <span className="c4">
          Except as expressly provided in these Terms and Conditions, no part of
          the Site, Services or Our Content may be copied, reproduced,
          aggregated, republished, uploaded, posted, publicly displayed,
          encoded, translated, transmitted, distributed, sold, licensed, or
          otherwise exploited for any commercial purpose whatsoever, without our
          express prior written permission.
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span>5.3 </span>
        <span className="c4">
          Provided that you are eligible to use the Site, you are granted a
          limited licence to access and use the Site and Our Content and to
          download or print a copy of any portion of the Content to which you
          have properly gained access solely for your personal, non-commercial
          use.
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span>5.4 </span>
        <span className="c4">
          You shall not (a) try to gain unauthorised access to the Site or any
          networks, servers or computer systems connected to the Site; and/or
          (b) make for any purpose including error correction, any
          modifications, adaptions, additions or enhancements to the Site or Our
          Content, including the modification of the paper or digital copies you
          may have downloaded.
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span>5.5 </span>
        <span className="c4">
          We shall (a) prepare the Site and Our Content with reasonable skill
          and care; and (b) use industry standard virus detection software to
          try to block the uploading of content to the Site that contains
          viruses.
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span>5.6 </span>
        <span className="c4">
          The content on the Site is provided for general information only. It
          is not intended to amount to advice on which you should rely. You must
          obtain professional or specialist advice before taking, or refraining
          from taking, any action on the basis of the content on the Site.
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span>5.7 </span>
        <span className="c4">
          Although we make reasonable efforts to update the information on our
          site, we make no representations, warranties or guarantees, whether
          express or implied, that Our Content on the Site is accurate, complete
          or up to date.
        </span>
        <span className="c8 c14"></span>
      </p>
      <p className="c1 c6">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span>6. Link to third party content</span>
        <span className="c12 c9"></span>
        <span className="c8 c14"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span>6.1</span>
        <span className="c4">
          The Site may contain links to websites or applications operated by
          third parties.We do not have any influence or control over any such
          third party websites or applications or the third party operator. We
          are not responsible for and do not endorse any third party websites or
          applications or their availability or content.
        </span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span>6.2 </span>
        <span className="c4">
          We accept no responsibility for adverts contained within the Site. If
          you agree to purchase goods and/or services from any third party who
          advertises in the Site, you do so at your own risk. The advertiser,
          and not us, is responsible for such goods and/or services and if you
          have any questions or complaints in relation to them, you should
          contact the advertiser.
        </span>
        <span className="c8 c14"> </span>
      </p>
      <p className="c1 c6">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span>7.</span>
        <span className="c12 c9"></span>
        <span></span>
        <span className="c9 c17">Site Management </span>
        <span className="c8 c9"></span>
        <span className="c8 c14"></span>
      </p>
      <p className="c1 c6">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span>7.1</span>
        <span className="c4">
          We reserve the right at our sole discretion, to (1) monitor the Site
          for breaches of these Terms and Conditions; (
        </span>
        <span>2</span>
        <span className="c4">
          ) take appropriate legal action against anyone in breach of applicable
          laws or these Terms and Conditions;{" "}
        </span>
        <span>
          (3) refuse, restrict access to or availability of, or disable (to the
          extent technologically feasible) any of your Contributions;
        </span>
        <span className="c4">(</span>
        <span>4</span>
        <span className="c4">
          ) remove from the Site or otherwise disable all files and content that
          are excessive in size or are in any way a burden to our systems; and (
        </span>
        <span>5</span>
        <span className="c4">
          ) otherwise manage the Site in a manner designed to protect our rights
          and property and to facilitate the proper functioning of the Site and
          Services.
        </span>
        <span className="c8 c14"></span>
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span>7.2 </span>
        <span className="c4">
          We do not guarantee that the Site will be secure or free from bugs or
          viruses.
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span>7.3 </span>
        <span className="c4">
          You are responsible for configuring your information technology,
          computer programs and platform to access the Site and you should use
          your own virus protection software.{" "}
        </span>
        <span className="c8 c14"></span>
      </p>
      <p className="c1 c6">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span>8.</span>
        <span className="c12 c9"></span>
        <span></span>
        <span className="c9 c17">
          Modifications to and availability of the Site
        </span>
        <span className="c8 c9"></span>
        <span className="c8 c14"></span>
      </p>
      <p className="c1 c6">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span>8.1 </span>
        <span className="c4">
          We reserve the right to change, modify, or remove the contents of the
          Site at any time or for any reason at our sole discretion without
          notice. We also reserve the right to modify or discontinue all or part
          of the Services without notice at any time.
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span>8.2 </span>
        <span className="c4">
          We cannot guarantee the Site and Services will be available at all
          times. We may experience hardware, software, or other problems or need
          to perform maintenance related to the Site, resulting in
          interruptions, delays, or errors. You agree that we have no liability
          whatsoever for any loss, damage, or inconvenience caused by your
          inability to access or use the Site or Services during any downtime or
          discontinuance of the Site or Services.We are not obliged to maintain
          and support the Site or Services or to supply any corrections,
          updates, or releases.
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span>8.3 </span>
        <span className="c4">
          There may be information on the Site that contains typographical
          errors, inaccuracies, or omissions that may relate to the Services,
          including descriptions, pricing, availability, and various other
          information. We reserve the right to correct any errors, inaccuracies,
          or omissions and to change or update the information at any time,
          without prior notice.{" "}
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span>9.</span>
        <span className="c12 c9"></span>
        <span></span>
        <span className="c9 c17">Disclaimer/Limitation of Liability</span>
        <span className="c8 c9"></span>
        <span className="c8 c14"></span>
      </p>
      <p className="c1 c6">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span>9.1</span>
        <span className="c4">
          The Site and Services are provided on an as-is and as-available basis.
          You agree that your use of the Site and/or Services will be at your
          sole risk except as expressly set out in these Terms and Conditions.
          All warranties, terms, conditions and undertakings, express or implied
          (including by statute, custom or usage, a course of dealing, or common
          law) in connection with the Site and Services and your use thereof
          including, without limitation, the implied warranties of satisfactory
          quality, fitness for a particular purpose and non-infringement are
          excluded to the fullest extent permitted by applicable law.
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span className="c4">
          We make no warranties or representations about the accuracy or
          completeness of the Site&rsquo;s content and are not liable for any
          (1) errors or omissions in content: (2) any unauthorized access to or
          use of our servers and/or any and all personal information and/or
          financial information stored on our server; (3) any interruption or
          cessation of transmission to or from the site or services; and/or (4)
          any bugs, viruses, trojan horses, or the like which may be transmitted
          to or through the site by any third party. We will not be responsible
          for any delay or failure to comply with our obligations under these
          Terms and Conditions if such delay or failure is caused by an event
          beyond our reasonable control.
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span>9.2</span>
        <span className="c4">
          Our responsibility for loss or damage suffered by you:
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span className="c5">
          Whether you are a consumer or a business user:
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span className="c11">&#9679;</span>
        <span className="c4">
          We do not exclude or limit in any way our liability to you where it
          would be unlawful to do so. This includes liability for death or
          personal injury caused by our negligence or the negligence of our
          employees, agents or subcontractors and for fraud or fraudulent
          misrepresentation.
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span className="c11">&#9679;</span>
        <span className="c4">
          If we fail to comply with these Terms and Conditions, we will be
          responsible for loss or damage you suffer that is a foreseeable result
          of our breach of these Terms and Conditions, but we would not be
          responsible for any loss or damage that were not foreseeable at the
          time you started using the Site/Services.
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span className="c4">
          Notwithstanding anything to the contrary contained in the
          Disclaimer/Limitation of Liability section, our liability to you for
          any cause whatsoever and regardless of the form of the action, will at
          all times be limited to a total aggregate amount equal to the greater
          of (a) the sum of &pound;5000 or (b) the amount paid, if any, by you
          to us for the Services/Site during the six (6) month period prior to
          any cause of action arising.
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span className="c5">If you are a business user</span>
        <span className="c4">:</span>
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span className="c4">
          We will not be liable to you for any loss or damage, whether in
          contract, tort (including negligence), breach of statutory duty, or
          otherwise, even if foreseeable, arising under or in connection with:
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span className="c11">&#9679;</span>
        <span className="c4">
          use of, or inability to use, our Site/Services; or
        </span>
        <span className="c8 c20"></span>
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c11">&#9679;</span>
        <span className="c4">
          use of or reliance on any content displayed on our Site.
        </span>
        <span className="c8 c20"></span>
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span className="c4">In particular, we will not be liable for:</span>
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span className="c11">&#9679;</span>
        <span className="c4">
          loss of profits, sales, business, or revenue;
        </span>
        <span className="c8 c20"></span>
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c11">&#9679;</span>
        <span className="c4">business interruption;</span>
        <span className="c8 c20"></span>
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c11">&#9679;</span>
        <span className="c4">loss of anticipated savings;</span>
        <span className="c8 c20"></span>
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c11">&#9679;</span>
        <span className="c4">
          loss of business opportunity, goodwill or reputation; or
        </span>
        <span className="c8 c20"></span>
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c11">&#9679;</span>
        <span className="c4">
          any indirect or consequential loss or damage.
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span className="c5">If you are a consumer user:</span>
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span className="c11">&#9679;</span>
        <span className="c4">
          Please note that we only provide our Site for domestic and private
          use. You agree not to use our Site for any commercial or business
          purposes, and we have no liability to you for any loss of profit, loss
          of business, business interruption, or loss of business opportunity.
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span className="c11">&#9679;</span>
        <span className="c4">
          If defective digital content that we have supplied, damages a device
          or digital content belonging to you and this is caused by our failure
          to use reasonable care and skill, we will either repair the damage or
          pay you compensation.
        </span>
        <span className="c8 c20"></span>
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span className="c11">&#9679;</span>
        <span className="c4">
          You have legal rights in relation to goods that are faulty or not as
          described. Advice about your legal rights is available from your local
          Citizens&#39; Advice Bureau or Trading Standards office. Nothing in
          these Terms and Conditions will affect these legal rights.
        </span>
        <span></span>
        <span className="c4"></span>
        <span className="c8 c14"></span>
      </p>
      <p className="c1 c6">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span>10.</span>
        <span className="c12 c9"></span>
        <span></span>
        <span className="c9 c17">Term and Termination </span>
        <span className="c8 c9"></span>
        <span className="c8 c14"></span>
      </p>
      <p className="c1 c6">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span>10.1 </span>
        <span className="c4">
          These Terms and Conditions shall remain in full force and effect while
          you use the Site or Services or are otherwise a user of the Site, as
          applicable. You may terminate your use or participation at any time,
          for any reason, by following the instructions for terminating user
          accounts in your account settings, if available, or by contacting us
          at info@healthconnect.online.
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span>10.2 </span>
        <span className="c4">
          Without limiting any other provision of these Terms and Conditions, we
          reserve the right to, in our sole discretion and without notice or
          liability, deny access to and use of the Site and the Services
          (including blocking certain IP addresses), to any person for any
          reason including without limitation for breach of any representation,
          warranty or covenant contained in these Terms and Conditions or of any
          applicable law or regulation.
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span className="c4">
          If we determine, in our sole discretion, that your use of the
          Site/Services is in breach of these Terms and Conditions or of any
          applicable law or regulation, we may terminate your use or
          participation in the Site and the Services or delete your profile and
          any content or information that you posted at any time, without
          warning, in our sole discretion.
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span>10.3 </span>
        <span className="c4">
          If we terminate or suspend your account for any reason set out in this
          Section 9, you are prohibited from registering and creating a new
          account under your name, a fake or borrowed name, or the name of any
          third party, even if you may be acting on behalf of the third party.
          In addition to terminating or suspending your account, we reserve the
          right to take appropriate legal action, including without limitation
          pursuing civil, criminal, and injunctive redress.
        </span>
        <span></span>
        <span className="c4"></span>
        <span className="c8 c14"></span>
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span>11.</span>
        <span className="c12 c9"></span>
        <span> </span>
        <span className="c9 c17">Mobile Application </span>
        <span className="c8 c9"></span>
        <span className="c8 c14"></span>
      </p>
      <p className="c1 c6">
        <span className="c3"></span>
      </p>
      <p className="c1">
        <span className="c3">
          11.1 If you access the Services via a mobile application, then we
          grant you a revocable, non-exclusive, non-transferable, limited right
          to install and use the mobile application on wireless electronic
          devices owned or controlled by you, and to access and use the mobile
          application on such devices strictly in accordance with the terms and
          conditions of this license.
        </span>
      </p>
      <p className="c1">
        <span className="c4"></span>
        <span className="c8 c14"></span>
      </p>
      <p className="c1 c6">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span>11.2 </span>
        <span className="c5 c13">For business users only</span>
        <span className="c4 c13">- You will not:</span>
        <span className="c3"></span>
      </p>
      <p className="c1">
        <span className="c4"></span>
        <span className="c8 c14"></span>
      </p>
      <p className="c1">
        <span className="c4 c13"></span>
      </p>
      <p className="c1">
        <span className="c4"></span>
        <span className="c8 c14"></span>
      </p>
      <p className="c1">
        <span className="c4 c13">
          (a) reverse engineer, decompile or otherwise try to discover the
          source code of the software/application unless you have first written
          to us requesting interoperability information and we have failed to
          provide you with that information or if we have failed to offer to
          provide you with interoperability information on reasonable
          conditions&quot;;
        </span>
        <span className="c3"></span>
      </p>
      <p className="c1">
        <span className="c4"></span>
        <span className="c8 c14"></span>
      </p>
      <p className="c1 c6">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c4 c13">
          (b) make any modification, adaptation, improvement, enhancement,
          translation or derivative work from the application;
        </span>
        <span className="c3"></span>
      </p>
      <p className="c1">
        <span className="c4"></span>
        <span className="c8 c14"></span>
      </p>
      <p className="c1 c6">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c4 c13">
          (c) breach any applicable laws, rules or regulations in connection
          with your access or use of the application;
        </span>
        <span className="c3"></span>
      </p>
      <p className="c1">
        <span className="c4"></span>
        <span className="c8 c14"></span>
      </p>
      <p className="c1 c6">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c4 c13">
          (d) remove, alter or obscure any proprietary notice (including any
          notice of copyright or trade mark) posted by us or the licensors of
          the application;
        </span>
        <span className="c3"></span>
      </p>
      <p className="c1">
        <span className="c4"></span>
        <span className="c8 c14"></span>
      </p>
      <p className="c1 c6">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c4 c13">
          (e) use the application for any revenue generating endeavor,
          commercial enterprise, or other purpose for which it is not designed
          or intended;
        </span>
        <span className="c3"></span>
      </p>
      <p className="c1">
        <span className="c4"></span>
        <span className="c8 c14"></span>
      </p>
      <p className="c1 c6">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c4 c13">
          (f) make the application available over a network or other environment
          permitting access or use by multiple devices or users at the same
          time;
        </span>
        <span className="c3"></span>
      </p>
      <p className="c1">
        <span className="c4"></span>
        <span className="c8 c14"></span>
      </p>
      <p className="c1 c6">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c4 c13">
          (g) use the application for creating a product, service or software
          that is, directly or indirectly, competitive with or in any way a
          substitute for the application;
        </span>
        <span className="c3"></span>
      </p>
      <p className="c1">
        <span className="c4"></span>
        <span className="c8 c14"></span>
      </p>
      <p className="c1 c6">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c4 c13">
          (h) use the application to send automated queries to any website or to
          send any unsolicited commercial e-mail; or
        </span>
        <span className="c3"></span>
      </p>
      <p className="c1">
        <span className="c4"></span>
        <span className="c8 c14"></span>
      </p>
      <p className="c1 c6">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c4 c13">
          (i) use any proprietary information or any of our interfaces or our
          other intellectual property in the design, development, manufacture,
          licensing or distribution of any applications, accessories or devices
          for use with the application.
        </span>
        <span className="c3"> </span>
      </p>
      <p className="c1">
        <span className="c4"></span>
        <span className="c8 c14"></span>
      </p>
      <p className="c1">
        <span className="c3">
          <br />
        </span>
      </p>
      <p className="c1">
        <span className="c3">
          11.3 The following terms apply when you use a mobile application
          obtained from either the Apple Store or Google Play (each an App
          Distributor) to access the Services:
        </span>
      </p>
      <p className="c1">
        <span className="c4"></span>
        <span className="c8 c14"></span>
      </p>
      <p className="c1 c6">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c3">
          (a) The licence granted to you for our mobile application is limited
          to a non-transferable licence to use the application on a device that
          utilizes the Apple iOS or Android operating system, as applicable, and
          in accordance with the usage rules set forth in the applicable App
          Distributor terms of service;
        </span>
      </p>
      <p className="c1">
        <span className="c4"></span>
        <span className="c8 c14"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span className="c3">
          (b) We are responsible for providing any maintenance and support
          services with respect to the mobile application as specified in these
          Terms and Conditions or as otherwise required under applicable law.
          You acknowledge that each App Distributor has no obligation whatsoever
          to furnish any maintenance and support services with respect to the
          mobile application;
        </span>
      </p>
      <p className="c1">
        <span className="c4"></span>
        <span className="c8 c14"></span>
      </p>
      <p className="c1 c6">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c3">
          (c) In the event of any failure of the mobile application to conform
          to any applicable warranty, you may notify an App Distributor, and the
          App Distributor, in accordance with its terms and policies, may refund
          the purchase price, if any, paid for the mobile application, and to
          the maximum extent permitted by applicable law, an App Distributor
          will have no other warranty obligation whatsoever with respect to the
          mobile application;
        </span>
      </p>
      <p className="c1">
        <span className="c4"></span>
        <span className="c8 c14"></span>
      </p>
      <p className="c1 c6">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c3">
          (d) You represent and warrant that (i) you are not located in a
          country that is subject to a U.S. government embargo, or that has been
          designated by the U.S. government as a &ldquo;terrorist
          supporting&rdquo; country; and (ii) you are not listed on any U.S.
          government list of prohibited or restricted parties;
        </span>
      </p>
      <p className="c1">
        <span className="c4"></span>
        <span className="c8 c14"></span>
      </p>
      <p className="c1 c6">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c3">
          (e) You must comply with applicable third party terms of agreement
          when using the mobile application, e.g., if you have a VoIP
          application, then you must not be in breach of their wireless data
          service agreement when using the mobile application; and
        </span>
      </p>
      <p className="c1">
        <span className="c4"></span>
        <span className="c8 c14"></span>
      </p>
      <p className="c1 c6">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c3">
          (f) You acknowledge and agree that the App Distributors are third
          party beneficiaries of these Terms and Conditions, and that each App
          Distributor will have the right (and will be deemed to have accepted
          the right) to enforce these Terms and Conditions against you as a
          third party beneficiary thereof.
        </span>
      </p>
      <p className="c1">
        <span className="c4"></span>
        <span className="c8 c14"></span>
      </p>
      <p className="c1">
        <span>12.</span>
        <span className="c9 c12"></span>
        <span></span>
        <span className="c9 c17">General </span>
        <span> </span>
        <span className="c8 c9"></span>
        <span className="c8 c14"></span>
      </p>
      <p className="c1 c6">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span>12.1</span>
        <span className="c4">
          Visiting the Site, sending us emails, and completing online forms
          constitute electronic communications. You consent to receive
          electronic communications and you agree that all agreements, notices,
          disclosures, and other communications we provide to you
          electronically, via email and on the Site, satisfy any legal
          requirement that such communication be in writing.
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span className="c5">
          You hereby agree to the use of electronic signatures, contracts,
          orders and other records and to electronic delivery of notices,
          policies and records of transactions initiated or completed by us or
          via the Site.
        </span>
        <span className="c4">
          You hereby waive any rights or requirements under any statutes,
          regulations, rules, ordinances or other laws in any jurisdiction which
          require an original signature or delivery or retention of
          non-electronic records, or to payments or the granting of credits by
          other than electronic means.
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span>12.2 </span>
        <span className="c4">
          These Terms and Conditions and any policies or operating rules posted
          by us on the Site or in respect to the Services constitute the entire
          agreement and understanding between you and us.
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span>12.3 </span>
        <span className="c4">
          Our failure to exercise or enforce any right or provision of these
          Terms and Conditions shall not operate as a waiver of such right or
          provision.
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span>12.4 </span>
        <span className="c4">
          We may assign any or all of our rights and obligations to others at
          any time.
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span>12.5 </span>
        <span className="c4">
          We shall not be responsible or liable for any loss, damage, delay or
          failure to act caused by any cause beyond our reasonable control.
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span>12.6 </span>
        <span className="c4">
          If any provision or part of a provision of these Terms and Conditions
          is unlawful, void or unenforceable, that provision or part of the
          provision is deemed severable from these Terms and Conditions and does
          not affect the validity and enforceability of any remaining
          provisions.
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span>12.7 </span>
        <span className="c4">
          There is no joint venture, partnership, employment or agency
          relationship created between you and us as a result of these Terms and
          Conditions or use of the Site or Services.
        </span>
        <span></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span className="c4">12.8 </span>
        <span className="c5 c13">For consumers only</span>
        <span className="c4 c13">
          - Please note that these Terms and Conditions, their subject matter
          and their formation, are governed by English law. You and we both
          agree that the courts of England and Wales will have exclusive
          jurisdiction expect that if you are a resident of Northern Ireland you
          may also bring proceedings in Northern Ireland, and if you are
          resident of Scotland, you may also bring proceedings in Scotland. If
          you have any complaint or wish to raise a dispute under these Terms
          and Conditions or otherwise in relation to the Site please follow this
          link
        </span>
        <span className="c8"></span>
        <span className="c8 c13 c22">
          <a
            className="c23"
            href="https://www.google.com/url?q=http://ec.europa.eu/odr&amp;sa=D&amp;ust=1587799380362000"
          >
            http://ec.europa.eu/odr
          </a>
        </span>
        <span className="c8 c13"></span>
        <span></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span className="c4">12.9 </span>
        <span className="c5 c13">For business users only</span>
        <span className="c4 c13">
          - If you are a business user, these Terms and Conditions, their
          subject matter and their formation (and any non-contractual disputes
          or claims) are governed by English Law. We both agree to the exclusive
          jurisdiction of the courts of England and Wales
        </span>
        <span className="c4">.</span>
        <span></span>
        <span className="c4"></span>
        <span className="c8 c14"></span>
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span>
          12.10 Except as stated under the Mobile Application section, a
        </span>
        <span className="c4">
          person who is not a party to these Terms and Conditions shall have no
          right under the Contracts (Rights of Third Parties) Act 1999 to
          enforce any term of these Terms and Conditions.
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1 c6">
        <span className="c0">
          <br />
        </span>
      </p>
      <p className="c1">
        <span>12.11 </span>
        <span className="c4">
          In order to resolve a complaint regarding the Services or to receive
          further information regarding use of the Services, please contact us
          by email at info@healthconnect.online or by post to:
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c4">
          <br />
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c4">
          MERWELL M&Uuml;HEND&#304;SL&#304;K L&#304;M&#304;TED
          &#350;&#304;RKET&#304;
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c4">
          ESENTEPE MAH. B&Uuml;Y&Uuml;KDERE CAD. OYAL
          I&#350;MR.AP.N.108/1/912,Istanbul, Turkey
        </span>
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c4">Istanbul, Turkey, ESENTEPE MAH __________</span>
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c4">Turkey</span>
        <span className="c4"></span>
      </p>
      <p className="c6 c18">
        <span className="c24"></span>
      </p>
    </div>
  );
};
