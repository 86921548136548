import React from "react";
import { Link } from "react-router-dom";

const Links = ({ style = { marginTop: 50, paddingBottom: 50 } }) => {
  return (
    <div style={style}>
      <Link style={{ fontSize: 13 }} to="/">
        Home{" "}
      </Link>
      .
      <Link style={{ fontSize: 13 }} to="/about-us">
        {" "}
        About Us{" "}
      </Link>
      .
      <Link style={{ fontSize: 13 }} to="/privacy-policy">
        {" "}
        Privacy Policy{" "}
      </Link>
      .
      <Link style={{ fontSize: 13 }} to="/terms-and-conditions">
        {" "}
        Terms And Conditions{" "}
      </Link>
      .
      <Link style={{ fontSize: 13 }} to="/contact-us">
        {" "}
        Contact Us{" "}
      </Link>
    </div>
  );
};

export default Links;
