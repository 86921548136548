import React from "react";

export const AboutUs = () => {
  return (
    <div>
      <h2>HealthConnect</h2>
      <p style={{ textAlign: "justify" }}>
        The application is designed to provide a medical platform for everyone
        with a medical, dental and health need. This will allow patients to
        search all different health and medical practitioners to search and find
        the relevant practitioners and check on their profile, book an
        appointment and even consult with the doctor even via a video
        conference. A large number of Google searches are allocated to the
        medical field. The medical market for most of the medical profession
        such as psychology, Cosmetic treatment, Dentists etc. is very
        competitive. There are several websites and mobile application that
        facilitate connections between patients and doctors worldwide or in
        Australia. However, most of these applications offer limited function
        through their app which may be only search, online booking or only video
        consultation option. Even the ones offering video consultation have a
        marketing module that is not attractive to doctors or patients. The
        applications which are available in Australia include: Healthengine,
        Whitecoat, Healthdirect, Medinet and GP2U. Some of these applications
        hire the doctors on commission bases contracts and deduct different
        commission rates from their doctors.
      </p>
    </div>
  );
};
