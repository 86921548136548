import React, { Component } from 'react';
import { CircularProgress} from '@material-ui/core';
import './Preloader.scss';

export default class Preloader extends Component {
    render(){
        const { size } = this.props;
        return(
            <CircularProgress size={size} />
        )
    }
}