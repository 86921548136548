import React from "react";

export const ContactUs = () => {
  return (
    <div>
      <p>Phone: 1300 70 70 17</p>
      <p>Email: Info@healthconnect.online</p>
      <p>Address: 3/ 460 Church Street Parramatta NSW 2150, Australia</p>
    </div>
  );
};
