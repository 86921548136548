import React, { Component, Fragment } from 'react'
import { List, ListItem, Card, CardHeader, Avatar, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import './Results.scss';
const styles = theme => ({
  root: {
    width: '100%',
  },
  inline: {
    display: 'inline',
  },
  title: {
    color: 'blue',
  }
});
class Results extends Component {
  showQRcode = () => {
    this.props.showQRcodeprop();
  }
  clearSearch = () => {
    this.setState({
      results: [],
      query: '',
    })
  }
  get_results = (apiSearchUrl, keyWord) => {
    this.setState({ message: '' }, () => this.props.apiCall(apiSearchUrl, keyWord));
  }
  options = (results, itemType) => {
    return (
      <Fragment>
        {results.length ? results.map((result, i) => {
          let hospitalIcon = "/static/panel/img/icon_hospital_light.png";
          let nofaceIcon = "/static/panel/img/icon_noface_light.png";
          if(i % 2 === 0){
            hospitalIcon = "/static/panel/img/icon_hospital_dark.png";
            nofaceIcon = "/static/panel/img/icon_noface_dark.png";
          }
          return (
            <ListItem key={i} alignItems="flex-start" >
              <Card className="card" onClick={() => this.showQRcode()}>
                <CardHeader
                  avatar={
                    <Avatar aria-label="recipe" className="avatar">
                      {
                        result.item_type === 'doctorprofile' ?
                          result.data.avatar === null ?
                            <img src={`${process.env.PUBLIC_URL}${nofaceIcon}`} alt='no face icon' width='15px' height='15px' />
                            : <img src={result.data.avatar} alt="Avatar" height='100%' /> :
                          <img src={`${process.env.PUBLIC_URL}${hospitalIcon}`} alt='hospital icon' width='20px' height='20px'/>
                      }
                    </Avatar>
                  }
                  action={
                    <div>
                      <div className="video-call" >
                        <img src={`${process.env.PUBLIC_URL}/static/panel/img/icon_webcam.svg`} alt='webcam' width='20px' />
                      </div>
                      <p className="txt-video-call">video call</p>
                    </div>
                  }
                  title={<b>{itemType === 'hospital' ? result.data.name : `${result.data.first_name} ${result.data.last_name} `}</b>}
                  subheader={itemType === 'hospital' ? result.data.interests.map((interest, i) => <span key={i}>{interest.name}</span>) : result.data.speciality_name.name}
                />
              </Card>
            </ListItem>
          )
        }) : null}
      </Fragment>
    )
  }
  render() {
    const { classes, results, currentItemType, currentItemName ,dataCount} = this.props;
    if (results.length)
      return (
        <div className="results">
          <b>SEARCH RESULTS for 
        <b> "{currentItemName}"</b></b><Button onClick={() => this.props.clearSearch()}
            className="clear-search">clear search</Button>
          <div className="results-list" id="scrollableDiv">
          <InfiniteScroll
              dataLength={dataCount}
              next={this.get_results}
              hasMore={this.props.hasMore}
              loader={<h4>loading..</h4>}
              scrollableTarget="scrollableDiv"
            >
               <List className={classes.root} >{this.options(results, currentItemType, currentItemName)}</List>
            </InfiniteScroll>
           
          </div>
        </div>
      );
    else return null;
  }
}
Results.propTypes = {
  results: PropTypes.array,
  classes: PropTypes.object
};
export default withStyles(styles)(Results);