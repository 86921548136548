import React from "react";

import "./PrivacyPolicy.css";

export const PrivacyPolicy = () => {
  return (
    <div>
      <p className="c9">
        <span className="c6 c12">Privacy Policy</span>
      </p>
      <p className="c3">
        <span className="c1">
          MERWELL M&Uuml;HEND&#304;SL&#304;K L&#304;M&#304;TED
          &#350;&#304;RKET&#304; built the Healthconnect Online app as a Free
          app. This SERVICE is provided by MERWELL M&Uuml;HEND&#304;SL&#304;K
          L&#304;M&#304;TED &#350;&#304;RKET&#304; at no cost and is intended
          for use as is.{" "}
        </span>
      </p>
      <p className="c3">
        <span className="c1">
          This page is used to inform visitors regarding our policies with the
          collection, use, and disclosure of Personal Information if anyone
          decided to use our Service.{" "}
        </span>
      </p>
      <p className="c3">
        <span className="c1">
          If you choose to use our Service, then you agree to the collection and
          use of information in relation to this policy. The Personal
          Information that we collect is used for providing and improving the
          Service. We will not use or share your information with anyone except
          as described in this Privacy Policy.{" "}
        </span>
      </p>
      <p className="c3">
        <span className="c1">
          The terms used in this Privacy Policy have the same meanings as in our
          Terms and Conditions, which is accessible at Healthconnect Online
          unless otherwise defined in this Privacy Policy.{" "}
        </span>
      </p>
      <p className="c3">
        <span className="c6">Information Collection and Use</span>
      </p>
      <p className="c3">
        <span className="c1">
          For a better experience, while using our Service, we may require you
          to provide us with certain personally identifiable information. The
          information that we request will be retained by us and used as
          described in this privacy policy.{" "}
        </span>
      </p>
      <p className="c3">
        <span className="c1">
          The app does use third party services that may collect information
          used to identify you.{" "}
        </span>
      </p>
      <p className="c3">
        <span className="c1">
          Link to privacy policy of third party service providers used by the
          app{" "}
        </span>
      </p>
      <ul className="lst-kix_list_1-0 start">
        <li className="c0">
          <span className="c5">
            <a
              className="c7"
              href="https://www.google.com/url?q=https://www.google.com/policies/privacy/&amp;sa=D&amp;ust=1587799774595000"
            >
              Google Play Services
            </a>
          </span>
        </li>
        <li className="c2">
          <span className="c1">Apple App Store</span>
        </li>
        <li className="c2">
          <span className="c1">Twilio Inc.</span>
        </li>
        <li className="c8">
          <span className="c1">DigitalOcean</span>
        </li>
      </ul>
      <p className="c3">
        <span className="c6">Log Data</span>
      </p>
      <p className="c3">
        <span className="c1">
          We want to inform you that whenever you use our Service, in a case of
          an error in the app we collect data and information (through third
          party products) on your phone called Log Data. This Log Data may
          include information such as your device Internet Protocol
          (&ldquo;IP&rdquo;) address, device name, operating system version, the
          configuration of the app when utilizing our Service, the time and date
          of your use of the Service, and other statistics.{" "}
        </span>
      </p>
      <p className="c3">
        <span className="c6">Cookies</span>
      </p>
      <p className="c3">
        <span className="c1">
          Cookies are files with a small amount of data that are commonly used
          as anonymous unique identifiers. These are sent to your browser from
          the websites that you visit and are stored on your device&#39;s
          internal memory.{" "}
        </span>
      </p>
      <p className="c3">
        <span className="c1">
          This Service does not use these &ldquo;cookies&rdquo; explicitly.
          However, the app may use third party code and libraries that use
          &ldquo;cookies&rdquo; to collect information and improve their
          services. You have the option to either accept or refuse these cookies
          and know when a cookie is being sent to your device. If you choose to
          refuse our cookies, you may not be able to use some portions of this
          Service.{" "}
        </span>
      </p>
      <p className="c3">
        <span className="c6">Service Providers</span>
      </p>
      <p className="c3">
        <span className="c1">
          We may employ third-party companies and individuals due to the
          following reasons:{" "}
        </span>
      </p>
      <ul className="lst-kix_list_2-0 start">
        <li className="c0">
          <span className="c1">To facilitate our Service;</span>
        </li>
        <li className="c2">
          <span className="c1">To provide the Service on our behalf;</span>
        </li>
        <li className="c2">
          <span className="c1">To perform Service-related services; or</span>
        </li>
        <li className="c8">
          <span className="c1">
            To assist us in analyzing how our Service is used.
          </span>
        </li>
      </ul>
      <p className="c3">
        <span className="c1">
          We want to inform users of this Service that these third parties have
          access to your Personal Information. The reason is to perform the
          tasks assigned to them on our behalf. However, they are obligated not
          to disclose or use the information for any other purpose.{" "}
        </span>
      </p>
      <p className="c3">
        <span className="c6">Security</span>
      </p>
      <p className="c3">
        <span className="c1">
          We value your trust in providing us your Personal Information, thus we
          are striving to use commercially acceptable means of protecting it.
          But remember that no method of transmission over the internet, or
          method of electronic storage is 100% secure and reliable, and we
          cannot guarantee its absolute security.{" "}
        </span>
      </p>
      <p className="c3">
        <span className="c6">Links to Other Sites</span>
      </p>
      <p className="c3">
        <span className="c1">
          This Service may contain links to other sites. If you click on a
          third-party link, you will be directed to that site. Note that these
          external sites are not operated by us. Therefore, we strongly advise
          you to review the Privacy Policy of these websites. We have no control
          over and assume no responsibility for the content, privacy policies,
          or practices of any third-party sites or services.{" "}
        </span>
      </p>
      <p className="c3">
        <span className="c6">Children&rsquo;s Privacy</span>
      </p>
      <p className="c3">
        <span className="c1">
          These Services do not address anyone under the age of 18. We do not
          knowingly collect personally identifiable information from children
          under 18. In the case we discover that a child under 18 has provided
          us with personal information, we immediately delete this from our
          servers. If you are a parent or guardian and you are aware that your
          child has provided us with personal information, please contact us so
          that we will be able to do necessary actions.{" "}
        </span>
      </p>
      <p className="c3">
        <span className="c6">Changes to This Privacy Policy</span>
      </p>
      <p className="c3">
        <span className="c1">
          We may update our Privacy Policy from time to time. Thus, you are
          advised to review this page periodically for any changes. We will
          notify you of any changes by posting the new Privacy Policy on this
          page. These changes are effective immediately after they are posted on
          this page.{" "}
        </span>
      </p>
      <p className="c3">
        <span className="c6">Contact Us</span>
      </p>
      <p className="c3">
        <span className="c1">
          If you have any questions or suggestions about our Privacy Policy, do
          not hesitate to contact us at info@Healthconnect.online.{" "}
        </span>
      </p>
      <p className="c13">
        <span className="c4"></span>
      </p>
    </div>
  );
};
