import React, { Component } from "react";
import axios from "axios";
import Results from "../Results/Results";
import Suggestions from "../Suggestions/Suggestions";
import Preloader from "../Preloader/Preloader";
import { withStyles } from "@material-ui/core/styles";
import { Paper, InputBase, IconButton } from "@material-ui/core";
import "./Search.scss";
import GetQRcode from "../GetQRcode/GetQRcode";

const API_SEARCH_URL = "https://panel.healthconnect.online/api/search/";
const API_SUGGESTION_URL =
  "https://panel.healthconnect.online/api/search/suggestions/";

const styles = (theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
  },
  progress: {
    margin: theme.spacing(2),
  },
  iconButton: {
    padding: 10,
  },
});

class Search extends Component {
  state = {
    query: "",
    results: [],
    suggests: [],
    show: "suggests",
    message: "",
    next: "",
    currentItemType: "",
    currentItemName: "",
    dataCount: 3,
    hasMore: true,
    isLoading: false,
  };
  isLoading = false;
  getInfo = (apiUrl, keyword) => {
    if ((!this.isLoading && this.state.next) || (apiUrl && keyword)) {
      this.isLoading = true;
      this.setState({ isLoading: true });
      axios
        .get(
          apiUrl && keyword
            ? `${apiUrl}${keyword}?country=us&offset=0&limit=7`
            : this.state.next
        ) //us gb
        .then((res) => {
          this.isLoading = false;
          // if (apiUrl === API_SEARCH_URL) {
          if (this.state.show === "results") {
            this.setState({
              results:
                apiUrl && keyword
                  ? res.data.content.results
                  : [...this.state.results, ...res.data.content.results],
              dataCount:
                apiUrl && keyword
                  ? res.data.content.count
                  : this.state.results.length + res.data.content.results.length,
              // results: res.data.content.results,
              // dataCount: res.data.content.count,
              next: res.data.content.next,
              isLoading: false,
              hasMore: res.data.content.next ? true : false,
            });
          } else if (this.state.show === "suggests") {
            this.setState({
              suggests:
                apiUrl && keyword
                  ? res.data.content.results
                  : [...this.state.suggests, ...res.data.content.results],
              dataCount:
                apiUrl && keyword
                  ? res.data.content.count
                  : this.state.suggests.length +
                    res.data.content.results.length,
              next: res.data.content.next,
              isLoading: false,
              hasMore: res.data.content.next ? true : false,
            });
          }
        })
        .catch((err) => {
          this.isLoading = false;
          if (err.response.status > 400 && err.response.status < 500) {
            this.setState({ message: err, hasMore: false, isLoading: false });
          } else if (err.response.status >= 500) {
            this.setState({
              message: "server error..please try later",
              isLoading: false,
              hasMore: false,
            });
          }
        });
    }
  };
  handleInputChange = (e) => {
    this.setState(
      {
        query: e.target.value,
        show: "suggests",
        isLoading: true,
      },
      () => {
        setTimeout(() => {
          const { query } = this.state;
          if (query.length) {
            this.setState({ message: "", results: [] }, () =>
              this.getInfo(API_SUGGESTION_URL, query)
            );
          } else if (!query.length) {
            this.setState({
              isLoading: false,
              message: "",
              suggests: [],
              results: [],
            });
          }
        }, 1000);
      }
    );
  };
  searchNow = () => {
    this.setState(
      {
        show: "results",
      },
      () => {
        const { results, query } = this.state;
        if (!results.length && !query.length)
          this.setState({
            isLoading: false,
            message: "please type some thing!",
          });
        else if (!results.length && query.length)
          this.setState({ message: "no result.." }, () =>
            this.getInfo(API_SUGGESTION_URL, query)
          );
        else if (results.length)
          this.setState({ message: "" }, () =>
            this.getInfo(API_SEARCH_URL, query)
          );
      }
    );
  };
  showResults = () => {
    this.setState({
      show: "results",
    });
  };
  showQRcode = () => {
    this.setState({
      ...this.state,
      show: "QRcode",
    });
  };
  clearSearch = () => {
    this.setState({
      suggests: [],
      query: "",
      results: [],
    });
  };
  render() {
    const { classes } = this.props;
    const {
      message,
      suggests,
      results,
      show,
      currentItemType,
      currentItemName,
      isLoading,
      query,
      dataCount,
      next,
      hasMore,
    } = this.state;
    return (
      <div className="search">
        <Paper className="paper-card">
          <InputBase
            required
            className="search-input"
            placeholder="Search for symptoms, doctors..."
            inputProps={{ "aria-label": "search google maps" }}
            onChange={this.handleInputChange}
            value={query}
          />
          {isLoading ? (
            <Preloader size={25} className="search-preloader" />
          ) : (
            <IconButton
              onClick={this.searchNow}
              className={classes.iconButton}
              aria-label="search"
            >
              <img
                src={`${process.env.PUBLIC_URL}/static/panel/img/search.svg`}
                className="search-logo"
                alt="search icon"
              />
            </IconButton>
          )}
        </Paper>
        {message ? (
          <p className="message text-main-page">{message}</p>
        ) : (
          <p className="text-main-page">
            Find a Doctor in one click. Easy video consultation with a doctor
            and free booking of an appointment
          </p>
        )}
        {show === "suggests" && suggests.length !== 0 && (
          <Suggestions
            suggests={suggests.length ? suggests : []}
            query={query.length ? query : ""}
            apiCall={this.getInfo}
            apiSearchUrl={API_SEARCH_URL}
            showResultsprop={this.showResults}
            currentItemTypeSetter={(currentItemType) =>
              this.setState({ currentItemType })
            }
            currentItemNameSetter={(currentItemName) =>
              this.setState({ currentItemName })
            }
            clearSearch={this.clearSearch}
            dataCount={dataCount}
            next={next}
            hasMore={hasMore}
            isLoading={this.set_is_loading}
          />
        )}
        {show === "results" && (
          <Results
            results={results.length ? results : []}
            apiCall={(apiUrl, keyWord) => this.getInfo(apiUrl, keyWord)}
            showQRcodeprop={this.showQRcode}
            currentItemType={currentItemType || ""}
            currentItemName={currentItemName || ""}
            clearSearch={this.clearSearch}
            dataCount={dataCount}
            next={next}
            hasMore={hasMore}
            isLoading={this.set_is_loading}
          />
        )}
        {show === "QRcode" && <GetQRcode />}
      </div>
    );
  }
}

export default withStyles(styles)(Search);
