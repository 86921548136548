import React, { Component, Fragment } from 'react'
import { List, ListItem, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import './Suggestions.scss';
import InfiniteScroll from 'react-infinite-scroll-component';
const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
  },
  inline: {
    display: 'inline',
  },
});

class Suggestions extends Component {
  showResults = async (suggest, itemTypeFixed) => {
    await this.props.currentItemTypeSetter(itemTypeFixed);
    await this.props.currentItemNameSetter(suggest.data.name);
    this.get_results(this.props.apiCall, this.props.apiSearchUrl, suggest.data.name);
    this.props.showResultsprop();
  }
  get_results = (apiCall,apiSearchUrl, keyWord) => {
    this.setState({ message: '' }, () => this.props.apiCall(apiSearchUrl, keyWord));
  }
  suggestopt = (suggests, itemType) => {
    if (suggests.length) {
      const filteredSuggests = suggests.filter(item => item.item_type === String(itemType));
      let itemTypeFixed = itemType === 'doctorprofile' ? 'Doctor Profile' : itemType;
      return (
        <Fragment>
          <b>{itemTypeFixed}</b>
          {filteredSuggests.length ? filteredSuggests.map((suggest, i) => {
            return (

              <ListItem key={i} onClick={() => this.showResults(suggest, itemTypeFixed)} alignItems="flex-start">
                {suggest.data.name}</ListItem>
            )
          }) : <ListItem alignItems="flex-start">No {itemTypeFixed} for this keyword!</ListItem>}
        </Fragment>
      )

    }
  }
  render() {
    const { classes, suggests, query, dataCount } = this.props;
    if (suggests.length)
      return (
        <div className="suggestion">
          <div className='clear-search-div'> <b>Suggestions for <b>"{query}"</b></b>
            <Button onClick={() => this.props.clearSearch()} className="clear-search">clear search</Button></div>
          <div className="suggests-list" id="scrollableDiv">
            <InfiniteScroll
              dataLength={dataCount}
              next={this.get_results}
              hasMore={this.props.hasMore}
              loader={<h4>loading..</h4>}
              scrollableTarget="scrollableDiv"
              endMessage="no more..!"
            >
              <List className={classes.root}>{this.suggestopt(suggests, 'symptom')}</List>
              <List className={classes.root}>{this.suggestopt(suggests, 'doctorprofile')}</List>
              <List className={classes.root}>{this.suggestopt(suggests, 'hospital')}</List>
            </InfiniteScroll>
          </div>
        </div>
      );
    else return null;
  }
}
Suggestions.propTypes = {
  results: PropTypes.array,
  classes: PropTypes.object
};
export default withStyles(styles)(Suggestions);

