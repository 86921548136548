import React, { Component } from "react";
import { isMobile, isAndroid, isIOS } from "react-device-detect";
import { Button } from "@material-ui/core";
import "./GetQRcode.scss";

export default class GetQRcode extends Component {
  state = {
    QRcode: "windows",
  };
  showIOSQRcode = () => {
    this.setState({
      QRcode: "IOS",
    });
  };
  showAndroidQRcode = () => {
    this.setState({
      QRcode: "Android",
    });
  };
  showWindowsQRcode = () => {
    this.setState({
      QRcode: "Windows",
    });
  };
  render() {
    const { QRcode } = this.state;
    if (isMobile) {
      if (isAndroid) return <div className="QR-code-page">isAndroid</div>;
      else if (isIOS) return <div className="QR-code-page">isIOS</div>;
    } else {
      if (QRcode === "IOS")
        return (
          <div className="QR-code-page">
            <h4>IOS QR Code</h4>
            <p>
              Simply scan the code with a QR code reader, then click on the
              provided install link.
            </p>
            <div className="QR-codes">
              <div className="QR-code">
                <img
                  src={`${process.env.PUBLIC_URL}/static/panel/img/android-qrcode.svg`}
                  alt="ios"
                />
              </div>
            </div>
            <Button onClick={() => this.showWindowsQRcode()}>
              back to choose os
            </Button>
          </div>
        );
      else if (QRcode === "Android")
        return (
          <div className="QR-code-page">
            <h4>Android QR Code</h4>
            <p>
              Simply scan the code with a QR code reader, then click on the
              provided install link.
            </p>
            <div className="QR-codes">
              <div className="QR-code">
                <img
                  src={`${process.env.PUBLIC_URL}/static/panel/img/ios-qrcode.svg`}
                  alt="android"
                />
              </div>
            </div>
            <Button onClick={() => this.showWindowsQRcode()}>
              back to choose os
            </Button>
          </div>
        );
      else
        return (
          <div className="QR-code-page">
            <br />
            <br />
            <p>
              To use the mhealthconnect platform features you should download
              and Install the mobile app.To install mobile app scan the proper
              QR code with your device.
            </p>
            <br />
            <br />
            <div className="QR-codes">
              <div
                className="QR-code"
                style={{ float: "left" }}
                onClick={() => this.showAndroidQRcode()}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/static/panel/img/icon_apple.svg`}
                  alt="ios"
                  className="qrcode-icon"
                />
              </div>
              <div
                className="QR-code"
                style={{ float: "left" }}
                onClick={() => this.showIOSQRcode()}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/static/panel/img/icon_android.svg`}
                  alt="android"
                  className="qrcode-icon"
                />
              </div>
            </div>
          </div>
        );
    }
  }
}
