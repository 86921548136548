import React from "react";
import { Container } from "@material-ui/core";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import Search from "./components/Search/Search";
import {
  AboutUs,
  TermsAndConditions,
  PrivacyPolicy,
  ContactUs,
} from "./components/screens";

import Links from "./components/Links";
import "./App.scss";

const App = () => {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path="/about-us">
            <Container fixed maxWidth="sm">
              <div className="logo-other-pages">
                <Link to="/">
                  <img
                    src={`${process.env.PUBLIC_URL}/static/panel/img/logo.svg`}
                    alt="logo"
                    style={{ width: "100%", height: "auto" }}
                  />
                </Link>
              </div>
              <AboutUs />
              <Links />
            </Container>
          </Route>
          <Route path="/contact-us">
            <Container fixed maxWidth="sm">
              <div className="logo-other-pages">
                <Link to="/">
                  <img
                    src={`${process.env.PUBLIC_URL}/static/panel/img/logo.svg`}
                    alt="logo"
                    style={{ width: "100%", height: "auto" }}
                  />
                </Link>
              </div>
              <ContactUs />
              <Links />
            </Container>
          </Route>
          <Route path="/terms-and-conditions">
            <Container fixed maxWidth="sm">
              <div className="logo-other-pages">
                <Link to="/">
                  <img
                    src={`${process.env.PUBLIC_URL}/static/panel/img/logo.svg`}
                    alt="logo"
                    style={{ width: "100%", height: "auto" }}
                  />
                </Link>
              </div>
              <TermsAndConditions />
              <Links />
            </Container>
          </Route>
          <Route path="/privacy-policy">
            <Container fixed maxWidth="sm">
              <div className="logo-other-pages">
                <Link to="/">
                  <img
                    src={`${process.env.PUBLIC_URL}/static/panel/img/logo.svg`}
                    alt="logo"
                    style={{ width: "100%", height: "auto" }}
                  />
                </Link>
              </div>
              <PrivacyPolicy />
              <Links />
            </Container>
          </Route>
          <Route path="/">
            <Container fixed maxWidth="sm" className="content">
              <div className="logo">
                <Link to="/">
                  <img
                    src={`${process.env.PUBLIC_URL}/static/panel/img/logo.svg`}
                    alt="logo"
                    style={{ width: "100%", height: "auto" }}
                  />
                </Link>
              </div>
              <Search />
              <Links style={{ marginTop: 100 }} />
            </Container>
          </Route>
        </Switch>
      </div>
    </Router>
  );
};

export default App;
